import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";

const CustomModal = ({
  title,
  children,
  size = "sm",
  show,
  close,
  ...props
}) => (
  <Modal centered size={size} show={show} onHide={close} {...props}>
    <Modal.Body className="px-4 py-4">
      <div className="d-flex justify-content-between align-items-center modal-title mb-3">
        <p className="text-uppercase mb-0 fw-bold">{title}</p>
        {close ? (
          <div
            role="button"
            style={{ postion: "relative", zIndex: "9999" }}
            onClick={close}
          >
            <MdClose className="fs-5 text-dark" />
          </div>
        ) : null}
      </div>
      <div className="">{children}</div>
    </Modal.Body>
  </Modal>
);

export default CustomModal;
